<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
    <b-form @submit.stop.prevent="handleSubmit(login)">
      <h3 class="text-center text-primary font-weight-normal mb-4">
        {{ $t("enter") }}
      </h3>
      <form-input
        v-model="email"
        class="input-field"
        :placeholder="$t('email')"
        veeName="email"
        :veeRules="{ required: true }"
        @input="email = email.toLowerCase()"
      />
      <form-input
        v-model="password"
        class="input-field"
        :placeholder="$t('password')"
        :type="passwordInputType"
        veeName="password"
        :veeRules="{ required: true }"
      >
        <slot name="default">
          <b-input-group-append is-text>
            <b-icon
              :icon="passwordIcon"
              class="clickable"
              variant="black"
              @click="changePasswordInputType"
            ></b-icon>
          </b-input-group-append>
        </slot>
      </form-input>
      <b-row class="no-gutters justify-content-between align-items-center pt-3">
        <b-col class="col-12 col-sm-auto">
          <b-button
            variant="primary"
            class="text-white text-uppercase"
            :disabled="invalid"
            block
            type="submit"
          >
            {{ $t("button.login") }}
          </b-button>
        </b-col>
        <b-col class="col-12 col-sm-auto mt-3 mt-sm-0 text-center">
          <b-link @click="goToPage('PasswordRecovery')">
            {{ $t("forgotPassword") }}
          </b-link>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { PARSE_JWT } from "./../../Utils";
import authMixin from "./../../mixins/authMixin";

import FormInput from "./../../../../console-components/BaseFormInput";
export default {
  components: {
    FormInput
  },
  data() {
    return {
      email: "",
      password: "",
      passwordInputType: "password"
    };
  },
  mixins: [authMixin],
  computed: {
    passwordIcon() {
      return this.passwordInputType === "password" ? "eye" : "eye-slash";
    }
  },
  methods: {
    changePasswordInputType() {
      this.passwordInputType =
        this.passwordInputType === "password" ? "text" : "password";
    },
    async login() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("clearAll", null, { root: true });

        const token = await this.$store.dispatch("auth/loginUser", {
          email: this.email,
          password: this.password
        });

        const userId = PARSE_JWT(token).userId;
        await this.$store.dispatch("auth/retrieveUser", userId);

        this.$store.commit("auth/SET_IS_LOGGED_IN", true);

        this.$router.replace({ name: "Dashboard" });
      } catch (error) {
        if (error.errorCode === 616)
          this.$router.push({
            name: "TokenRecovery",
            params: { email: this.email }
          });
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    goToPage(routeName) {
      this.$router.push({ name: routeName });
    }
  }
};
</script>

<style lang="scss" scoped>
.input-field {
  /deep/ {
    .form-control {
      background-color: var(--gray-light);
    }
    .input-group-text {
      background-color: var(--gray-light);
    }
  }
}
</style>
